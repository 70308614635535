<template>
	<span
		class="badge badge-pill  text-light mr-1"
		:class="{
			'orange darken-2': expiring <= 30,
			'blue-grey lighten-3': expiring > 30 && license.approved,
			red: !license.approved
		}"
		>{{ license.state }}
		<span v-if="expiring <= 30"
			>expiring in {{ expiring }} day{{ expiring === 1 ? "" : "s" }}.</span
		>
		<!--		{{ license.approved }}-->
	</span>
</template>

<script>
import moment from "moment";

export default {
	name: "LicensePill.vue",
	props: ["license"],
	data() {
		return {
			today: moment()
		};
	},
	computed: {
		expiring() {
			//let today = moment()// today's date,
			return -1 * moment().diff(this.license.expiration, "days");
		}
	}
};
</script>

<style scoped></style>
