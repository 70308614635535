// Welcome.vue

<template>
	<div class="wrap_avatar" style="">
		<a
			href="#"
			class="large_avatar trigger_avatar_change"
			:class="user.color"
			v-on:click.prevent="triggerAvatarChange"
		>
			<div class="avatar_img" :style="backgroundImage">
				<span v-show="user.self" class="btn btn-primary btn-sm"
					>Change Avatar</span
				>
			</div>
			<!-- <img v-if="selectedImage" src>
      <img v-else class="" style="" width="100%" src="/static/avatar2.png" data-holder-rendered="true"> -->
		</a>
		<!-- <input type="file" v-on:change="changeImage" name="image">
         <a href="" @click.prevent="">Change Image</a>

         {{image}} -->

		<input
			type="file"
			class="avatar_file_input"
			v-on:change="changeImage"
			name="image"
		/>
		<input
			v-if="selectedImage"
			v-model="selectedImage.name"
			type="hidden"
			name="photo"
		/>
	</div>
</template>

<script>
export default {
	props: ["user"],

	data() {
		return {
			allowableTypes: ["jpg", "jpeg", "png", "gif"],
			maximumSize: 5000000,
			selectedImage: null,
			image: null,
			options: {
				url: "https://httpbin.org/post",
				type: "POST",
				processData: false,
				contentType: false
			}
		};
	},

	computed: {
		backgroundImage() {
			return (
				"background-image: url('" +
				(this.selectedImage ? this.image : "/static/avatar2.png") +
				"')"
			);
			//return "background-image: url(''"+ ((this.selectedImage)? this.image : '/static/avatar2.png') + "'')";
		}
	},

	watch: {
		// totalCalls() {
		//   this.initPieChart();
		// },
	}, //watch

	methods: {
		validate(image) {
			if (
				!this.allowableTypes.includes(
					image.name
						.split(".")
						.pop()
						.toLowerCase()
				)
			) {
				alert(
					`Sorry, you can only upload ${this.allowableTypes
						.join("|")
						.toUpperCase()} files.`
				);
				return false;
			}

			if (image.size > this.maximumSize) {
				alert("Sorry. File size exceeding from 5 Mb");
				return false;
			}

			return true;
		},
		onImageError(err) {
			console.log(err, "do something with error");
		},
		changeImage($event) {
			this.selectedImage = $event.target.files[0];
			//validate the image
			if (!this.validate(this.selectedImage)) return;
			// create a form
			//const form = new FormData();
			//form.append('file', this.selectedImage);
			// submit the image
			// $.ajax(Object.assign({}, this.options, {data: form}))
			//   .then(this.createImage)
			//   .catch(this.onImageError);

			this.createImage();
		},
		createImage() {
			//const image = new Image()
			const reader = new FileReader();
			reader.onload = e => {
				this.image = e.target.result;

				//var downSizedImg = this.resizedataURL(this.image, 500, 500);

				this.$emit("newAvatarSelected", this.image);
			};

			reader.readAsDataURL(this.selectedImage);
		},

		triggerAvatarChange() {
			if (this.user.self) {
				// eslint-disable-next-line no-undef
				$("a.trigger_avatar_change")
					.closest(".wrap_avatar")
					.find(".avatar_file_input")
					.trigger("click");
			}
		},

		resizedataURL(datas, wantedWidth, wantedHeight) {
			// We create an image to receive the Data URI
			var img = document.createElement("img");

			// When the event "onload" is triggered we can resize the image.
			img.onload = function() {
				// We create a canvas and get its context.
				var canvas = document.createElement("canvas");
				var ctx = canvas.getContext("2d");

				// We set the dimensions at the wanted size.
				canvas.width = wantedWidth;
				canvas.height = wantedHeight;

				// We resize the image with the canvas method drawImage();
				ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

				var dataURI = canvas.toDataURL();

				return dataURI;

				/////////////////////////////////////////
				// Use and treat your Data URI here !! //
				/////////////////////////////////////////
			};

			// We put the Data URI in the image's src attribute
			//img.src = datas;
		}
		// Use it like that : resizedataURL('yourDataURIHere', 50, 50);
	}, // methods

	mounted() {
		//this.initPieChart();
	}
};
</script>

<style lang="scss"></style>
