import { render, staticRenderFns } from "./LicensePill.vue?vue&type=template&id=37af06c0&scoped=true&"
import script from "./LicensePill.vue?vue&type=script&lang=js&"
export * from "./LicensePill.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37af06c0",
  null
  
)

export default component.exports