<template>
	<tr style="">
		<td style="text-align: center">{{ index + 1 }}</td>
		<th>{{ facility.name }}</th>
		<td>
			<strong>{{ facility.city }}</strong
			>, {{ facility.state }}
		</td>
		<td :class="canAddUser ? 'text-secondary' : 'text-danger'">
			{{
				facility.user_limit > 0
					? facility.users_count + " of " + facility.user_limit
					: "Unlimited"
			}}
		</td>
		<td>
			<!--			canAddUser: {{ canAddUser }}-->
			<a
				href
				@click.prevent="addLicenseModalShow(facility.state)"
				v-if="!hasLicenseFor && isFacilityRTChecked"
				class="btn btn-danger ml-2"
				><i class="material-icons">report_problem</i>Missing State License</a
			>
		</td>
		<td class="text-center">
			<div class="btn-group-toggle" data-toggle="buttons">
				<label
					:disabled="!canAddUser"
					class="btn btn-sm-"
					:class="{
						'text-light amber darken-3': isFacilityAdminChecked,
						'btn-outline-secondary': !isFacilityAdminChecked
					}"
				>
					<input
						:disabled="(user.self && user.rtnow_admin != true) || !canAddUser"
						type="checkbox"
						v-model="user.admin_facilities"
						id="admin_facilities_option[]"
						:value="facility.id"
					/><i v-if="isFacilityAdminChecked" class="material-icons -lime-text"
						>check</i
					>
					<i v-else="" class="material-icons plus">close</i>Admin
				</label>
			</div>
		</td>

		<td class="text-center">
			<div class="btn-group-toggle" data-toggle="buttons">
				<label
					:disabled="!canAddUser"
					class="btn btn-sm-"
					:class="{
						'text-light blue darken-1': isFacilityRTChecked,
						'btn-outline-secondary': !isFacilityRTChecked
					}"
				>
					<input
						:disabled="
							(user.self &&
								!isFacilityAdminChecked &&
								user.rtnow_admin != true) ||
								isFacilityBlockChecked ||
								!canAddUser
						"
						type="checkbox"
						v-model="user.rt_facilities"
						id="rt_facility_option[]"
						:value="facility.id"
					/><i v-if="isFacilityRTChecked" class="material-icons -lime-text"
						>check</i
					>
					<i v-else="" class="material-icons plus">close</i>
					<span
						:class="{
							strikethrough: isFacilityBlockChecked
						}"
						>RT</span
					>
				</label>
			</div>
		</td>

		<td v-show="selfUser.rtnow_admin" class="text-center">
			<div class="btn-group-toggle" data-toggle="buttons">
				<label
					class="btn btn-sm-"
					:class="{
						'text-light grey darken-4': isFacilityBlockChecked,
						'btn-outline-secondary': !isFacilityBlockChecked
					}"
				>
					<input
						title="Do not route calls from this facility."
						:disabled="
							user.self && !isFacilityAdminChecked && user.rtnow_admin != true
						"
						type="checkbox"
						v-model="user.blacklist_facilities"
						id="blacklist_facilities_option[]"
						:value="facility.id"
					/><i v-if="isFacilityBlockChecked" class="material-icons -lime-text"
						>check</i
					>
					<i v-else="" class="material-icons plus">close</i>Block
				</label>
			</div>
		</td>
	</tr>
</template>

<script>
export default {
	name: "UserFacilitiesList.vue",
	props: ["facility", "index", "user", "selfUser"],
	computed: {
		canAddUser() {
			if (
				this.facility.user_limit == -1 ||
				this.isFacilityRTChecked ||
				this.isFacilityAdminChecked
			) {
				return true;
			} else if (this.facility.users_count < this.facility.user_limit) {
				return true;
			}
			return false;
		},
		isFacilityAdminChecked() {
			return (
				this.user.admin_facilities &&
				this.user.admin_facilities.includes(this.facility.id)
			);
		},
		isFacilityRTChecked() {
			return (
				this.user.rt_facilities &&
				this.user.rt_facilities.includes(this.facility.id)
			);
		},
		hasLicenseFor() {
			if (!this.user.licenses) {
				return false;
			}
			return this.user.licenses.find(row => {
				return row.state === this.facility.state;
			});
		},
		isFacilityBlockChecked() {
			return (
				this.user.blacklist_facilities &&
				this.user.blacklist_facilities.includes(this.facility.id)
			);
		}
	},
	methods: {
		// hasLicenseFor() {
		// 	if (!this.user.licenses) {
		// 		return false;
		// 	}
		// 	return this.user.licenses.find(row => {
		// 		return row.state === this.facility.state;
		// 	});
		// },
		// isFacilityAdminChecked(facilityId) {
		// 	return (
		// 		this.user.admin_facilities &&
		// 		this.user.admin_facilities.includes(facilityId)
		// 	);
		// },
		// isFacilityRTChecked(facilityId) {
		// 	return (
		// 		this.user.rt_facilities && this.user.rt_facilities.includes(facilityId)
		// 	);
		// },
		// isFacilityBlockChecked() {
		// 	return (
		// 		this.user.blacklist_facilities &&
		// 		this.user.blacklist_facilities.includes(this.facility.id)
		// 	);
		// }
	}
};
</script>

<style scoped></style>
