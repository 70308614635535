// users/List.vue // list RTs

<template>
	<div class="container-fluid">
		<div class="row justify-content-between mb-4">
			<div class="col-sm">
				<h1>
					Users
					<div class="btn-group mb-1" role="group">
						<button
							v-on:click.stop.prevent="showFacilities = !showFacilities"
							id="btnGroupDrop1"
							type="button"
							class="btn btn-light btn-outline dropdown-toggle"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<small class="btn-label">facility</small>
							{{ filter.facility_name }}
						</button>
						<div
							class="dropdown-menu"
							:class="{ show: showFacilities }"
							v-on-clickaway="hideFacilities"
						>
							<!-- <a class="dropdown-item" href="#">Dropdown link</a>
							<a class="dropdown-item" href="#">Dropdown link</a>-->
							<a
								href
								@click.prevent="filterFacility(false)"
								class="dropdown-item"
								style="opacity: .4"
								>All Facilities</a
							>
							<a
								href
								@click.prevent="filterFacility(f)"
								v-for="(f, i) in options.facilities"
								:key="i + '-user-list-facilities-options'"
								class="dropdown-item"
								>{{ f.name }}</a
							>
						</div>
					</div>
				</h1>

				<pre>filter: {{ filter }}</pre>

				<div class="mb-4 text-left">
					<!-- <div class="btn-group mb-1"  role="group">
                <button v-on:click.stop.prevent="showFacilities = !showFacilities" id="btnGroupDrop1" type="button" class="btn btn-light btn-outline dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <small class="btn-label">facility</small>
                  {{filter.facility_name}}
                </button>
                <div class="dropdown-menu" :class="{'show': showFacilities}" v-on-clickaway="hideFacilities">

                  <a href @click.prevent="filterFacility(false)" class="dropdown-item" style="opacity: .4">All Facilities</a>
                  <a href @click.prevent="filterFacility(f)" v-for="(f) in options.facilities" class="dropdown-item"  >{{f.name}}</a>

                </div>
					</div>-->

					<div class="btn-group-toggle" data-toggle="buttons">
						<label
							v-show="selfUser.rtnow_admin"
							class="btn btn-sm-"
							:class="{
								'text-light blue-grey lighten-2': filter.rtNowAdmins == 1,
								'btn-outline-secondary': filter.rtNowAdmins != 1,
								hide: filter.facility_id
							}"
						>
							<input
								type="checkbox"
								v-model="filter.rtNowAdmins"
								id="filterUsers[]"
								:value="1"
							/>
							<i
								v-if="filter.rtNowAdmins == 1"
								class="material-icons -lime-text"
								>check</i
							>
							<i v-else class="material-icons plus">close</i>rtNOW Admins
						</label>

						<label
							v-show="selfUser.rtnow_admin"
							class="btn btn-sm-"
							:class="{
								'text-light blue-grey lighten-1': filter.rtNowRts == 1,
								'btn-outline-secondary': filter.rtNowRts != 1,
								hide: filter.facility_id
							}"
						>
							<input
								type="checkbox"
								v-model="filter.rtNowRts"
								id="filterUsers[]"
								:class="{ hide: filter.facility_id }"
								:value="1"
							/>
							<i v-if="filter.rtNowRts == 1" class="material-icons -lime-text"
								>check</i
							>
							<i v-else class="material-icons plus">close</i>rtNOW RTs
						</label>

						<label
							class="btn btn-sm-"
							:class="{
								'text-light blue-grey darken-2': filter.facilityAdmins == 1,
								'btn-outline-secondary': filter.facilityAdmins != 1
							}"
						>
							<input
								type="checkbox"
								v-model="filter.facilityAdmins"
								id="filterUsers[]"
								:value="1"
							/>
							<i
								v-if="filter.facilityAdmins == 1"
								class="material-icons -lime-text"
								>check</i
							>
							<i v-else class="material-icons plus">close</i>Facility Admins
						</label>

						<label
							class="btn btn-sm-"
							:class="{
								'text-light blue-grey darken-3': filter.facilityRTs == 1,
								'btn-outline-secondary': filter.facilityRTs != 1
							}"
						>
							<input
								type="checkbox"
								v-model="filter.facilityRTs"
								id="filterUsers[]"
								:value="1"
							/>
							<i
								v-if="filter.facilityRTs == 1"
								class="material-icons -lime-text"
								>check</i
							>
							<i v-else class="material-icons plus">close</i>Facility RTs
						</label>

						<!-- ARCHIVED -->

						<label
							class="btn btn-sm-"
							:class="{
								'text-light red darken-2': filter.archived == 1,
								'btn-outline-secondary': filter.archived != 1
							}"
						>
							<input
								type="checkbox"
								v-model="filter.archived"
								id="filterUsers[]"
								:value="1"
							/>
							<i v-if="filter.archived == 1" class="material-icons -lime-text"
								>check</i
							>
							<i v-else class="material-icons plus">close</i>Archived
						</label>

						<a href class="btn" @click.prevent="clearFilters()">
							<small>
								<i class="material-icons plus">close</i>Clear Filters
							</small>
						</a>
					</div>
				</div>
			</div>
			<div class="col-auto mr-auto">
				<!-- <router-link class="btn btn-lg btn-success" :to="{ name: 'userFormAdd', params: {  } }" ><i class="material-icons">&#xE7FE;</i>Add New</router-link> -->

				<button
					type="button"
					class="btn btn-lg btn-success"
					data-dismiss="modal"
					@click.prevent="findUserModal.show = true"
				>
					<i class="material-icons">&#xE7FE;</i>Add New
				</button>
			</div>
		</div>

		<div class="_table-responsive">
			<table class="table table-bordered">
				<thead>
					<tr>
						<th scope="col" style="text-align: center">ID</th>
						<th scope="col"></th>
						<th scope="col">First</th>
						<th scope="col">Last</th>
						<th scope="col">Email</th>
						<th scope="col">Mobile #</th>
						<th scope="col">Licenses</th>
					</tr>
				</thead>
				<tbody>
					<app-user-row
						v-for="(user, index) in users"
						:user="user"
						:colors="options.colors"
						:filter="filter"
						:key="index"
						:page="page"
						@archiveUser="archiveUser(user)"
						@reinstateUser="reinstateUser(user)"
					></app-user-row>
				</tbody>
			</table>
		</div>

		<app-modal :modal="findUserModal">
			<span slot="title">Add User</span>

			<form v-on:submit.prevent="findUser">
				<div class="form-row">
					<div class="col mb-0">
						<!-- <label for="validationCustomUsername">Email</label> -->
						<div class="input-group input-group-lg">
							<div class="input-group-prepend">
								<span id="inputGroupPrepend" class="input-group-text">@</span>
							</div>
							<input
								ref="email"
								v-model="findUserModal.email"
								id="validationCustomUsername"
								placeholder="Enter Email"
								required="required"
								class="form-control"
								aria-required="true"
								aria-invalid="false"
								type="email"
							/>
							<div class="invalid-feedback">Please enter an email address.</div>
						</div>
					</div>
				</div>
			</form>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-secondary"
					data-dismiss="modal"
					@click.prevent="findUserModal.show = false"
				>
					Close
				</button>
				<button type="button" class="btn btn-primary" @click.prevent="findUser">
					Next
				</button>
			</template>
		</app-modal>
	</div>
</template>

<script>
import UserListRow from "./UserListRow.vue";

import { mapGetters, mapActions } from "vuex";

import { directive as onClickaway } from "vue-clickaway";

export default {
	//props: ['userType'],
	directives: {
		onClickaway: onClickaway
	},

	data() {
		return {
			findUserModal: {
				show: false,
				email: ""
			},
			users: [],
			page: {
				heading: "Users",
				getUrl: "/user/get", // user/list
				removeUrl: "/user/delete",
				saveUrl: "/user/save"
			},
			filter: {
				archived: false,
				rtNowAdmins: true,
				rtNowRts: true,
				clientAdmins: true,
				clientRTs: true,
				facilityAdmins: true,
				facilityRTs: true,
				facility_name: "All Facilities",
				facility_id: null
			},
			showFacilities: false,
			options: {
				facilities: [],
				colors: []
			}
		};
	},

	// watch: {
	// 	// 'findUserModal.show': function (foo) {
	// 	//   if(this.findUserModal.show == true){
	// 	//
	// 	//   }
	// 	// },
	// },

	methods: {
		...mapActions(["ajax", "getAllUsers"]),

		findUser() {
			//alert(this.findUserModal.email);

			this.ajax({
				url: "/user/get/" + encodeURIComponent(this.findUserModal.email),
				data: { filter: this.filter, return_error: false }, //
				success: json => {
					//this.options = json.options;

					if (json.user) {
						//alert('found user');
						this.$router.push("/user/edit/" + json.user.id);
					} else {
						var email = encodeURIComponent(this.findUserModal.email);
						this.$router.push("/user/add/" + email);
					}

					//this.$set(this, 'users', json.users);
					//this.$set(this, 'options', json.options);

					//console.log('context', context);
				}
			});
		},

		hideFacilities() {
			this.showFacilities = false;
		},

		filterFacility(f) {
			if (f) {
				this.filter.facility_id = f.id;
				this.filter.facility_name = f.name;
			} else {
				// resetting to all Facilities
				this.filter.facility_id = null;
				this.filter.facility_name = "All Facilities";
			}
			this.showFacilities = false;
		},

		reinstateUser(user) {
			/*
        the api call to delete from the server is made from within UserListRow.vue
        this method simply needs to remove the object fro the array of
      */

			console.log("reinstateUser", user);

			// find the array index of this object
			var i = this.users.indexOf(user);

			// delete the array child at a given index
			//this.$delete(this.users, i);
			this.users[i].banned = 0;
		},

		archiveUser(user) {
			/*
        the api call to delete from the server is made from within UserListRow.vue
        this method simply needs to remove the object fro the array of
      */

			console.log("user", user);

			// find the array index of this object
			var i = this.users.indexOf(user);

			// delete the array child at a given index
			//this.$delete(this.users, i);
			this.users[i].banned = 1;
		},

		clearFilters() {
			this.filter = {
				archived: false,
				rtNowAdmins: false,
				rtNowRts: false,
				clientAdmins: false,
				clientRTs: false,
				facilityAdmins: false,
				facilityRTs: false,
				facility_name: "All Facilities",
				facility_id: null
			};
		},

		initPage() {
			this.ajax({
				url: this.page.getUrl,
				data: { filter: this.filter },
				success: json => {
					//this.options = json.options;

					this.$set(this, "users", json.users);
					this.$set(this, "options", json.options);

					//console.log('context', context);
				}
			});
		}
	},

	watch: {
		userType() {
			this.initPage();
		},

		// deep watch

		// for later

		// filter: {
		//     handler: function(newValue) {
		//
		//         console.log('watch scheduleFilter');
		//         this.initPage();
		//     },
		//     deep: true
		// },

		"filter.facility_id": function() {
			this.initPage();
		}
	},

	computed: {
		...mapGetters([
			"selfUser"
			//'allUsers'
		]),

		userType: function() {
			return this.$route.params.userType;
		}
	},

	mounted() {
		setTimeout(() => {
			//this.getAllUsers();
		}, 1000);

		this.initPage();
	},

	components: {
		"app-user-row": UserListRow
	}
};
</script>

<style lang="scss"></style>
