//RTListRow.vue

<template>
	<tr v-show="showMe" :class="{ 'table-primary': user.self }">
		<th style="text-align: center">
			<div class="dropdown">
				<button
					v-on:click.stop.prevent="showColors = !showColors"
					class="btn btn-light dropdown-toggle"
					type="button"
					id="dropdownMenuButton[]"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<i :class="'filterDot fc-' + user.color"></i>
				</button>
				<div
					class="dropdown-menu"
					:class="{ show: showColors }"
					aria-labelledby="dropdownMenuButton"
				>
					<a
						v-for="color in colors"
						class="dropdown-item"
						@click.prevent="selectColor(user, color.color)"
						href="#"
						:key="'color-' + color.color"
					>
						<i :class="'filterDot fc-' + color.color"></i>
					</a>
					<!-- <a class="dropdown-item" @click.prevent="selectColor(user,'blue')" href="#"> <i :class="'filterDot fc-'+'blue'"></i></a>
					<a class="dropdown-item" @click.prevent="selectColor(user,'orange')" href="#"> <i :class="'filterDot fc-'+'orange'"></i></a>-->
				</div>
			</div>

			<!-- <span class="filterDot" :class="''+user.color"></span> -->
			<!-- {{user.id}} -->
		</th>
		<td>
			<button
				v-if="!user.self && user.banned == 1"
				type="button"
				class="btn btn-outline-danger"
				v-on:click.prevent="unArchiveUser(false)"
			>
				Reinstate
			</button>

			<button
				v-if="!user.self && user.banned == 0"
				type="button"
				class="btn btn-outline-danger"
				v-on:click.prevent="archiveUser(false)"
				style="margin-right: .25em"
			>
				Archive
			</button>
			<router-link
				v-if="user.banned == 0"
				class="btn btn-outline-secondary"
				:to="{ name: 'userFormEdit', params: { id: user.id } }"
				>Edit</router-link
			>
			<!-- <router-link class="btn btn-outline-secondary" :to="'/schedule/user/'+user.id">Schedule</router-link> -->
		</td>

		<td>{{ user.first_name }}</td>
		<td>{{ user.last_name }}</td>
		<td>
			{{ user.email }}
			<br />
			<span
				v-if="user.verified && user.banned == 1"
				class="badge badge-pill badge-danger text-light"
				>Archived</span
			>
			<span
				v-if="!user.verified"
				class="badge badge-pill badge-danger text-light"
				>Unverified</span
			>
			<!-- <a v-if="!user.verified" class="btn btn-outline-secondary" href>Resend Invitation</a> -->
			<span>
				<span
					v-if="user.rtnow_admin"
					class="badge badge-pill blue-grey lighten-2 text-light mr-1"
					>rtNOW Admin</span
				>
				<span
					v-if="user.rtnow_rt"
					class="badge badge-pill blue-grey lighten-1 text-light  mr-1"
					>rtNOW RT</span
				>

				<span
					v-if="user.admin_clients && user.admin_clients.length"
					class="badge badge-pill blue-grey text-light  mr-1"
					>Client Admin</span
				>
				<span
					v-if="user.rt_clients && user.rt_clients.length"
					class="badge badge-pill blue-grey text-light darken-1  mr-1"
					>Client RT</span
				>

				<span
					v-if="user.admin_facilities && user.admin_facilities.length"
					class="badge badge-pill blue-grey darken-2 text-light  mr-1"
					>Facility Admin</span
				>
				<span
					v-if="user.rt_facilities && user.rt_facilities.length"
					class="badge badge-pill blue-grey darken-3 text-light"
					>Facility RT</span
				>
			</span>
		</td>

		<td>{{ user.mobile }}</td>

		<td>
			<license-pill
				v-for="license in user.licenses"
				:license="license"
				:key="'license-pill-' + license.id"
			></license-pill>
			<router-link
				v-if="user.licenses.length == 0 && user.rt_facilities.length"
				class="btn btn-sm btn-danger ml-0"
				:to="{ name: 'userFormEdit', params: { id: user.id } }"
			>
				<i class="material-icons">report_problem</i>Missing State License
			</router-link>
		</td>

		<app-modal :modal="confirmArchiveModal">
			<span slot="title">Confirm Archive</span>

			<form>This user will be blocked from this system.</form>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-secondary"
					data-dismiss="modal"
					v-on:click.prevent="confirmArchiveModal.show = false"
				>
					Cancel
				</button>
				<button
					type="button"
					class="btn btn-danger"
					v-on:click.prevent="archiveUser(true)"
				>
					Archive
				</button>
			</template>
		</app-modal>

		<app-modal :modal="confirmUnArchiveModal">
			<span slot="title">Confirm Reinstatement</span>

			<form>This user will be allowed access to this system.</form>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-secondary"
					data-dismiss="modal"
					v-on:click.prevent="confirmUnArchiveModal.show = false"
				>
					Cancel
				</button>
				<button
					type="button"
					class="btn btn-danger"
					v-on:click.prevent="unArchiveUser(true)"
				>
					Reinstate
				</button>
			</template>
		</app-modal>
	</tr>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import LicensePill from "../../components/LicensePill.vue";

export default {
	props: ["user", "page", "filter", "colors"],

	components: {
		LicensePill
	},

	data() {
		return {
			confirmArchiveModal: {
				show: false
			},
			confirmUnArchiveModal: {
				show: false
			},
			showColors: false
		};
	},

	watch: {
		usersWithoutColor() {}
	},

	methods: {
		...mapActions(["ajax", "getSelfUser", "setSelfColor"]),

		selectColor(user, color) {
			//user.color = color;
			this.$set(user, "color", color);
			this.showColors = false;

			this.ajax({
				url: "/user/save_color/",
				data: { user_id: user.id, color: color },
				success: () => {
					if (user.id == this.selfUser.id) {
						//alert('its me!');
						this.setSelfColor(color);
					}
				}
			});
		},

		archiveUser: function(confirmed) {
			//alert(confirmed);

			if (!confirmed) {
				this.confirmArchiveModal.show = true;
			} else {
				//var tis = this;

				this.ajax({
					url: "/user/archive/" + this.user.id,
					data: {},
					success: json => {
						if (!json.archived) {
							alert("API error.  Could not archive user.");
						} else {
							this.$emit("archiveUser");
						}
					}
				});

				this.confirmArchiveModal.show = false;
			}
		},

		unArchiveUser: function(confirmed) {
			//alert(confirmed);

			if (!confirmed) {
				this.confirmUnArchiveModal.show = true;
			} else {
				//var tis = this;

				this.ajax({
					url: "/user/reinstate/" + this.user.id,
					data: {},
					success: json => {
						if (!json.reinstated) {
							alert("API error.  Could not reinstate user.");
						} else {
							this.$emit("reinstateUser");
						}
					}
				});

				this.confirmUnArchiveModal.show = false;
			}
		}
	},

	computed: {
		...mapGetters(["selfUser"]),

		showMe() {
			if (this.user.banned == 1 && this.filter.archived == false) {
				return false;
			}

			if (
				!this.filter.clientAdmins &&
				!this.filter.clientRTs &&
				!this.filter.facilityAdmins &&
				!this.filter.facilityRTs &&
				!this.filter.rtNowAdmins &&
				!this.filter.rtNowRts
			) {
				return true;
			}

			return (
				(this.filter.archived && this.user.banned == 1) ||
				(this.filter.clientAdmins && this.user.admin_clients.length) ||
				(this.filter.clientRTs && this.user.rt_clients.length) ||
				(this.filter.facilityAdmins && this.user.admin_facilities.length) ||
				(this.filter.facilityRTs && this.user.rt_facilities.length) ||
				(this.filter.rtNowAdmins && this.user.rtnow_admin) ||
				(this.filter.rtNowRts && this.user.rtnow_rt)
			);

			//return true;
		}
	},

	mounted() {
		//var tis = this;

		if (!this.user.color) {
			//var randomColor = _.sample(this.colors);

			var randomColor = this.colors[
				Math.floor(Math.random() * this.colors.length)
			];

			console.log(this.user.id + " has no color " + randomColor.color);

			this.selectColor(this.user, randomColor.color);
		}

		/*		this.ajax({

			url: '/rtlist/read',
			data: {},
			success: function(json,context){

				tis.rt= json.rt;

			}
		});*/
	}

	// components: {
	// 	'app-rt-row': RTListRow
	// },
};
</script>

<style lang="scss"></style>
