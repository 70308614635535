//UserForm.vue

<template>
	<form
		ref="form"
		class="container-fluid"
		novalidate
		v-on:submit.prevent="submitForm"
		:class="{ 'was-validated': errors.items.length > 0 && formSubmitted }"
	>
		<div class="row justify-content-between mb-4">
			<div class="col-sm">
				<h1>
					<span style="text-transform: capitalize">{{ formMode }}</span>
					<span v-if="user.self">Yourself</span><span v-else>User</span>
				</h1>
				<p class="lead text-muted">
					Manage system users.
				</p>
			</div>

			<div class="col-auto mr-auto">
				<a class="btn btn-lg -btn-success" href v-on:click.prevent="goBack()"
					>Cancel</a
				>
				<button
					:disabled="ajaxLoader"
					:class="{ 'btn-loading': ajaxLoader }"
					type="submit"
					class="btn btn-lg btn-success -btn-loading"
				>
					Save
				</button>
			</div>
		</div>

		<!-- <code>$route.params.id {{$route.params.id}}</code>
 -->

		<pre>user: {{ user }}</pre>

		<!-- <pre>options: {{options}}</pre> -->

		<div class="col-sm mb-4">
			<!-- <a href="#" class="large_avatar" :class="user.color" >
           <img class="" :alt="user.first_name" style="" width="100%" src="/static/avatar2.png" data-holder-rendered="true">
         </a> -->

			<app-upload-avatar
				:user="user"
				@newAvatarSelected="newAvatarSelected"
			></app-upload-avatar>
		</div>

		<div class="form-row">
			<div class="col-md-6 mb-3">
				<label for="firstName">First name</label>

				<input
					:disabled="disableUserField"
					type="text"
					class="form-control form-control-lg"
					id="firstName"
					name="First Name"
					placeholder="First name"
					v-model="user.first_name"
					v-validate="'required'"
					required
				/>
			</div>

			<div class="col-md-6 mb-3">
				<label for="last_name">Last name</label>

				<input
					:disabled="disableUserField"
					type="text"
					name="Last Name"
					class="form-control form-control-lg"
					id="last_name"
					placeholder="Last name"
					v-model="user.last_name"
					v-validate="'required'"
					required
				/>
			</div>

			<div class="col-md-6 mb-3">
				<label for="credentials">Credentials</label>

				<input
					:disabled="disableUserField"
					type="text"
					name="credentials"
					class="form-control form-control-lg"
					id="credentials"
					placeholder="Credentials"
					v-model="user.credentials"
				/>
			</div>

			<div class="col-md-6 mb-3">
				<label for="email">Email</label>
				<div class="input-group input-group-lg">
					<div class="input-group-prepend">
						<span class="input-group-text" id="inputGroupPrepend">@</span>
					</div>

					<input
						:disabled="disableUserField"
						type="email"
						name="email"
						class="form-control"
						id="email"
						placeholder="Email"
						v-model="user.email"
						v-validate="'required|email'"
						required
					/>

					<div class="invalid-feedback">
						Please enter an email address.
					</div>
				</div>
			</div>

			<div class="col-md-6 mb-3">
				<label for="mobile">Mobile Phone</label>
				<div class="input-group input-group-lg" ref="mobile">
					<div class="input-group-prepend">
						<span class="input-group-text" id="inputGroupPrepend2">#</span>
					</div>

					<input
						:disabled="disableUserField"
						type="number"
						class="form-control"
						name="mobile"
						id="mobile"
						placeholder="Enter a mobile phone number"
						v-model="user.mobile"
						v-validate="'required'"
						required
					/>

					<div class="invalid-feedback">
						Please enter a mobile phone number with numerals only.
					</div>
				</div>
			</div>

			<div class="col-md-6 mb-3">
				<label for="license_state">Pod</label>

				<select
					id="pod"
					name="pod"
					required="required"
					class="custom-select form-control-lg"
					v-model="user.pod"
				>
					<option value="">- Pod -</option>
					<option
					v-for="pod in options.pods"
					:value="pod.name"
					:key="'pods_' + pod.name"
					>
					{{ pod.name }} - {{ pod.states.join(', ') }}
					</option>
				</select>

			</div>


		</div>

		<div>
			<label for="mobile">Bio</label>
			<div class="input-group input-group-lg" ref="mobile">
				<textarea-autosize
						name="text"
						placeholder="The text goes here..."
						ref="someName"
						v-model="user.bio"
						:min-height="1"
						:max-height="350"
						class="form-control"
				></textarea-autosize>
			</div>
		</div>


		<div class="row mt-3">
			<div
				v-for="(val, key, n) in options.user_association_options"
				:key="n + '-user-associations-options'"
				class="col-6 col-sm-4 "
			>
				<div class="custom-control custom-checkbox form-control-lg">
					<input
						v-model="user.associations"
						type="checkbox"
						:id="'association_options-' + n"
						:name="'association_options-' + n"
						class="custom-control-input"
						:value="key"
					/>
					<label
						:for="'association_options-' + n"
						class="custom-control-label"
						>{{ val }}</label
					>
				</div>
			</div>
		</div>

		<section v-show="selfUser.rtnow_admin">
			<div class="pt-5">
				<h3><i class="material-icons">voice_chat</i>rtNOW Roles</h3>
				<p class=" text-muted">
					Assign user as a registered therapist (RT) or admin for each client.
				</p>
			</div>

			<div class="btn-group-toggle" data-toggle="buttons">
				<label
					class="btn btn-sm-"
					:disabled="user.self"
					:class="{
						'text-light amber darken-3': user.rtnow_admin,
						'btn-outline-secondary': !user.rtnow_admin,
						disabled: user.self
					}"
				>
					<input
						:disabled="user.self"
						type="checkbox"
						v-model="user.rtnow_admin"
						id="rtnow_admin_option[]"
						:value="1"
					/><i v-if="user.rtnow_admin" class="material-icons -lime-text"
						>check</i
					>
					<i v-else="" class="material-icons plus">close</i>Admin
				</label>

				<label
					class="btn btn-sm-"
					:disabled="user.self && !user.rtnow_admin"
					:class="{
						'text-light blue darken-1': user.rtnow_rt,
						'btn-outline-secondary': !user.rtnow_rt,
						disabled: user.self && !user.rtnow_admin
					}"
				>
					<input
						:disabled="user.self && !user.rtnow_admin"
						type="checkbox"
						v-model="user.rtnow_rt"
						id="rtnow_rt_option[]"
						:value="1"
					/><i v-if="user.rtnow_rt" class="material-icons -lime-text">check</i>
					<i v-else="" class="material-icons plus">close</i>RT
				</label>

				<a
					href
					@click.prevent="addLicenseModalShow('')"
					v-if="user.rtnow_rt && user.licenses.length == 0"
					class="btn btn-danger ml-0"
				>
					<i class="material-icons">report_problem</i>Missing State License...
				</a>
			</div>
		</section>

		<section v-if="false" class="">
			<div class="pt-5">
				<h3><i class="material-icons">work</i>Client Roles</h3>
				<p class=" text-muted">
					Assign user as a respiratory therapist (RT) or admin for each client.
				</p>
			</div>

			<pre>
user.rt_clients: {{ user.rt_clients }}
  user.admin_clients: {{ user.admin_clients }}</pre
			>

			<div class="table-responsive">
				<table class="table -table-bordered">
					<thead>
						<tr>
							<th scope="col" style="text-align: center">#</th>
							<th scope="col">Client</th>
							<th scope="col">State</th>
							<!-- <th align="right" style="text-align: right"><a class="btn btn-outline-primary" href="">Assign Hospital</a></th> -->
							<th style="text-align: center"></th>
							<th style="text-align: center">Admin</th>
							<th style="text-align: center">RT</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(client, index) in options.clients"
							:key="client.id"
							style=""
						>
							<td style="text-align: center">{{ index + 1 }}</td>
							<th>{{ client.name }}</th>
							<td>
								<strong>{{ client.bill_city }}</strong
								>, {{ client.bill_state }}
							</td>
							<td>
								<a
									href
									@click.prevent="addLicenseModalShow(client.bill_state)"
									v-if="
										!hasLicenseFor(client.bill_state) &&
											isClientRTChecked(client.id)
									"
									class="btn btn-danger ml-2"
									><i class="material-icons">report_problem</i>Missing State
									License )))</a
								>
							</td>
							<td class="text-center">
								<div class="btn-group-toggle" data-toggle="buttons">
									<label
										class="btn btn-sm-"
										:class="{
											'text-light amber darken-3': isClientAdminChecked(
												client.id
											),
											'btn-outline-secondary': !isClientAdminChecked(client.id)
										}"
									>
										<input
											:disabled="user.self"
											type="checkbox"
											v-model="user.admin_clients"
											id="admin_clients_option[]"
											:value="client.id"
										/><i
											v-if="isClientAdminChecked(client.id)"
											class="material-icons -lime-text"
											>check</i
										>
										<i v-else="" class="material-icons plus">close</i>Admin
									</label>
								</div>
							</td>
							<td class="text-center">
								<div class="btn-group-toggle" data-toggle="buttons">
									<label
										class="btn btn-sm-"
										:class="{
											'text-light blue darken-1': isClientRTChecked(client.id),
											'btn-outline-secondary': !isClientRTChecked(client.id)
										}"
									>
										<input
											:disabled="user.self && !isClientAdminChecked(client.id)"
											type="checkbox"
											v-model="user.rt_clients"
											id="rt_facility_option[]"
											:value="client.id"
										/><i
											v-if="isClientRTChecked(client.id)"
											class="material-icons -lime-text"
											>check</i
										>
										<i v-else="" class="material-icons plus">close</i>RT
									</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>

		<section>
			<div class="pt-5">
				<h3><i class="material-icons">location_city</i>Facility Roles</h3>
				<p class=" text-muted">
					Assign user as a registered therapist (RT) or admin for each hospital
					or facility.
				</p>
			</div>

			<pre>user.admin_facilities: {{ user.admin_facilities }}</pre>
			<pre>user.rt_facilities: {{ user.rt_facilities }}</pre>
			<!-- user.admin_facilities: {{user.admin_facilities}}</pre> -->

			<div class="table-responsive">
				<table class="table -table-bordered">
					<thead>
						<tr>
							<th scope="col" style="text-align: center">#</th>
							<th scope="col">Facility</th>
							<th scope="col">State</th>
							<th scope="col">Users</th>
							<!-- <th align="right" style="text-align: right"><a class="btn btn-outline-primary" href="">Assign Hospital</a></th> -->
							<th style="text-align: center"></th>
							<th style="text-align: center">Admin</th>
							<th style="text-align: center">RT</th>
							<th
								v-show="selfUser.rtnow_admin"
								style="text-align: center"
								title="Do not route calls from this facility."
							>
								Block
							</th>
						</tr>
					</thead>
					<tbody v-if="options.facilities.length">
						<user-facilities-list
							v-for="(facility, index) in options.facilities"
							:key="facility.id"
							:index="index"
							:facility="facility"
							:user="user"
							:selfUser="selfUser"
						>
						</user-facilities-list>
					</tbody>
				</table>
			</div>
		</section>

		<section class="pb-3">
			<div class="pt-5">
				<h3>State Licenses</h3>
				<p class=" text-muted">
					Enter any state license required for this user.
				</p>
			</div>

			<pre>user.licenses: {{ user.licenses }}</pre>

			<div class="table-responsive">
				<table class="table -table-bordered">
					<thead>
						<tr>
							<th scope="col" style="text-align: center">#</th>
							<th scope="col">State</th>
							<th scope="col">Abbr</th>
							<th scope="col" colspan="2">Approved</th>
							<th scope="col">Expires</th>
							<th align="right" style="text-align: right">
								<a
									v-if="
										((user.rtnow_admin ||
											user.rtnow_rt ||
											user.admin_facilities.length ||
											user.admin_clients.length) &&
											user.self) ||
											!user.self
									"
									class="btn btn-outline-primary"
									href=""
									@click.prevent="addLicenseModalShow('')"
									>Add License</a
								>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(license, index) in user.licenses"
							:key="index + '-user-form-licenses'"
							:class="{ 'table-danger': license.deleted }"
						>
							<td style="text-align: center">{{ index + 1 }}</td>

							<td v-if="options.states">{{ options.states[license.state] }}</td>
							<td align="center">
								<license-pill
									:license="license"
									:key="'user-form-license-pill-' + license.id"
								></license-pill>
							</td>
							<td align="right">
								<i
									v-if="license.approved"
									class=" material-icons  light-green-text mr-0 mt-1"
									>check_circle</i
								>
								<i v-else class=" material-icons red-text mt-1">cancel</i>
							</td>
							<td align="left">
								<span v-if="license.approved">{{
									license.approved | moment("dddd, MMM Do, YYYY")
								}}</span>
							</td>
							<td align="left">
								{{ license.expiration | moment("dddd, MMM Do, YYYY") }}
							</td>
							<td class="text-right" align="right">
								<span class="text-danger" v-if="license.deleted"
									>This will be removed.</span
								>
								<div v-else>
									<a
										v-if="
											(license.id &&
												!license.deleted &&
												(user.rtnow_admin ||
													user.admin_facilities.length ||
													user.admin_clients.length) &&
												user.self) ||
												!user.self
										"
										class="btn btn-outline-secondary  "
										v-on:click.prevent="editLicense(license)"
										href=""
										>Edit</a
									>
									<a
										v-if="
											(!license.deleted &&
												(user.rtnow_admin ||
													user.admin_facilities.length ||
													user.admin_clients.length) &&
												user.self) ||
												!user.self
										"
										class="btn btn-outline-danger ml-1"
										v-on:click.prevent="removeLicense(license, false)"
										href=""
										>Remove</a
									>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>

		<button
			:disabled="ajaxLoader"
			:class="{ 'btn-loading': ajaxLoader }"
			class="btn btn-success btn-lg mt-3"
			type="submit"
		>
			Save
		</button>

		<app-modal :modal="addLicenseModal">
			<span v-if="!addLicenseModal.license.id" slot="title"
				>Add New License</span
			>
			<span v-if="addLicenseModal.license.id" slot="title">Edit License</span>

			<form v-on:submit.prevent="saveLicense">
				<pre>addLicenseModal: {{ addLicenseModal }}</pre>

				<div class="form-row">
					<div class="col mb-3">
						<label for="license_state">State</label>

						<select
							id="license_state"
							name="State"
							required="required"
							class="custom-select form-control-md"
							v-model="addLicenseModal.license.state"
						>
							<option value="">- State -</option>
							<option
								v-for="(key, value) in options.states"
								:value="value"
								:key="'license_state_' + value"
								>{{ key }}</option
							>
						</select>
					</div>
				</div>

				<div class="form-row">
					<div class="col mb-3">
						<label for="expiration_date">Expiration Date</label>

						<datepicker
							v-validate="'required'"
							id="expiration_date"
							name="expiration_date"
							v-model="addLicenseModal.license.expiration"
							:format="'MM/dd/yyyy'"
							input-class="form-control"
						></datepicker>
					</div>
				</div>

				<div class="form-check" v-show="!user.self">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="addLicenseModal.license.approved"
						value=""
						id="approved"
						name="approved"
					/>
					<label class="form-check-label" for="approved">
						License approved?
					</label>
				</div>

				<div class="custom-file mt-3">
					<input type="file" class="custom-file-input" id="customFile" />
					<label class="custom-file-label" for="customFile">Choose file</label>
				</div>
			</form>

			<template slot="footer">
				<button
					v-show="!addLicenseModal.license.id"
					type="button"
					class="btn btn-secondary"
					data-dismiss="modal"
					@click.prevent="addLicenseModal.show = false"
				>
					Close
				</button>
				<button
					:disabled="addLicenseModal.license.state == ''"
					type="button"
					class="btn btn-primary"
					v-on:click.prevent="saveLicense"
				>
					Done
				</button>
			</template>
		</app-modal>

		<app-modal :modal="assignFacilityModal">
			<span slot="title">Assign Facility</span>

			<form></form>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-secondary"
					data-dismiss="modal"
					@click.prevent="assignFacilityModal.show = false"
				>
					Close
				</button>
				<button type="button" class="btn btn-primary">Save</button>
			</template>
		</app-modal>
	</form>
</template>

<script>
import moment from "moment";

import { mapGetters, mapActions } from "vuex";

import UserFacilitiesList from "./UserFacilitiesList.vue";
import UploadAvatar from "./UploadAvatar.vue";

import LicensePill from "../../components/LicensePill.vue";

import Datepicker from "vuejs-datepicker";

export default {
	props: [],

	data() {
		return {
			saved: true,
			error: false,
			formSubmitted: false,
			license: {},
			initial_user: {},
			user: {
				first_name: "",
				last_name: "",
				credentials: "",
				email: "",
				emailVerified: false,
				mobile: "",
				rtnow_admin: false,
				rtnow_rt: false,
				rt_facilities: [], // an array of IDs
				admin_facilities: [], // an array of IDs
				blacklist_facilities: [],
				rt_clients: [],
				admin_clients: [],
				licenses: [],
				id: "",
				bio: "",
				pod: "",
			},
			options: {
				facilities: []
			},
			addLicenseModal: {
				show: false,
				license: {
					id: null,
					state: "",
					expiration: moment().format("MM/DD/YYYY"),
					approved: null,
					documentation: null,
					deleted: false
				}
				//stateSelected: '',
				//expiration_date: moment().format("MM/DD/YYYY"),
			},
			assignFacilityModal: {
				show: false
			}
		};
	},

	computed: {
		...mapGetters(["ajaxLoader", "selfUser"]),

		formMode() {
			return this.$route.params.id ? "edit" : "add";
		},

		disableUserField() {
			// this is my account
			// I can edit my own name and email
			if (this.user.self) {
				return false;
			}

			// this is a brand new account being added
			// user's name and email can be entered the first time
			if (this.formMode === "add") {
				return false;
			}

			// otherwise, disable this input
			return true;
		}

		// showFacilityOptions(){
		//   return this.user.associations.includes('fac');
		// },
		//
		// showClientOptions(){
		//   return this.user.associations.includes('cli');
		// }
	},

	watch: {
		$route() {
			//watch for route to chage in case user id param has changed in the uri
			this.readUser();
		},

		"user.blacklist_facilities": function() {
			this.user.blacklist_facilities.forEach(facility_id => {
				console.log("facility_id: " + facility_id);

				// var remove_me = this.user.rt_facilities.filter(function(item) {
				//   return item == facility_id;
				// });

				var indexof = this.user.rt_facilities.findIndex(element => {
					return element == facility_id;
				});

				if (indexof > -1) {
					this.user.rt_facilities.splice(indexof, 1);
				}

				console.log("indexof: " + indexof);
			});
		}
	}, // watch

	methods: {
		...mapActions(["ajax", "setSelfName", "globalAlert"]),

		newAvatarSelected(newImage) {
			//alert(newImage);

			this.$set(this.user, "new_avatar", newImage);
		},

		addLicenseModalShow(state) {
			this.$set(this.addLicenseModal, "show", true);
			this.$set(this.addLicenseModal.license, "state", state);
			this.$set(this.addLicenseModal.license, "approved", false);
		},

		isClientRTChecked(clientId) {
			return this.user.rt_clients && this.user.rt_clients.includes(clientId);
		},

		isClientAdminChecked(clientId) {
			return (
				this.user.admin_clients && this.user.admin_clients.includes(clientId)
			);
		},

		hasLicenseFor(state) {
			if (!this.user.licenses) {
				return false;
			}
			return this.user.licenses.find(row => {
				return row.state === state;
			});
		},

		saveLicense() {
			if (!this.addLicenseModal.license.id) {
				// for new
				let copy = JSON.parse(JSON.stringify(this.addLicenseModal.license));

				this.user.licenses.push(copy);

				// for next time
				setTimeout(() => {
					this.addLicenseModal.license.state = "";
					this.addLicenseModal.license.approved = null;
				}, 500);
			}

			// all new or old
			this.addLicenseModal.show = false;

			//
		},

		removeLicense(license, confirmed) {
			if (!confirmed) {
				var c = confirm("This License record will be permenantly removed.");

				if (c) {
					this.removeLicense(license, true);
				}
			}
			// confirmed, mark as deleted
			else {
				//license.deleted = true;
				if (license.id) {
					this.$set(license, "deleted", true);
				} else {
					// find the array index of this object
					let i = this.user.licenses.indexOf(license);
					// actually delete the array child at a given index
					this.$delete(this.user.licenses, i);
				}

				// delete the array child at a given index
				//this.$delete(this.user.licenses, i);
			}
		},

		editLicense(license) {
			this.addLicenseModal.license = license;
			this.addLicenseModal.show = true;
		},

		goBack() {
			this.$router.push("/users");
		},

		submitForm() {
			this.formSubmitted = true;

			this.$refs["form"].classList.remove("was-validated");

			var url =
				"/user/save" +
				(this.$route.params.id ? "/" + this.$route.params.id : "");

			//alert('TODO: check for validation errors when mobiel contains non-numeric characters.');

			this.$validator.validateAll().then(result => {
				if (result) {
					var id = this.user.id ? this.user.id : "";

					if (this.user.self) {
						this.setSelfName(this.user);
					}

					this.ajax({
						url: url,
						data: this.user,
						success: json => {
							if (id == "") {
								// new
								//alert('saved new');
							} else {
								// updated
								//alert('updated');
							}

							if (json.error) {
								this.$set(this, "error", json.error);

								this.$refs["form"].classList.add("was-validated");

								return false;
							} else if (json.validation_errors) {
								this.$refs["form"].classList.add("was-validated");

								//this.$refs['mobile'].classList.add("error");

								return false;
							} else {
								this.formSubmitted = false;

								this.goBack();
							}
						}
					});

					return;
				}

				//alert('Correct them errors!');

				this.globalAlert({
					title: "Form Errors",
					body: "Please correct errors."
				});
			}); // validateAll
		}, //  submit form

		readUser() {
			var tis = this;

			var user_id = this.$route.params.id;

			// if editing RT
			if (!isNaN(this.$route.params.id)) {
				console.log("edit user");

				this.user.id = user_id;

				this.ajax({
					url: "/user/init/" + user_id,
					//data: {},
					success: json => {
						this.user = json.user;
						this.initial_user = JSON.parse(JSON.stringify(json.user));
						this.options = json.options;
						this.saved = false;
					}
				});
			}

			// new RT
			else {
				console.log("new user");

				this.user.id = "";

				this.ajax({
					url: "/user/init",
					data: {},
					success: json => {
						//tis.rt= json.rt;
						tis.options = json.options;

						this.user.email = this.$route.params.email;
					}
				});
			}
		}
	},

	mounted() {
		this.readUser();
	},

	components: {
		UserFacilitiesList,
		"app-upload-avatar": UploadAvatar,
		Datepicker,
		LicensePill
	}
};
</script>

<style lang="scss">
.material-icons {
	transition-duration: 0.3s;
}
.material-icons.lime-text {
	opacity: 1;
}

.opacity-40 {
	opacity: 0.4;
}

.material-icons.plus {
	transform: rotate(45deg);
}

.strikethrough {
	text-decoration: line-through;
}
</style>
